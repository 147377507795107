import React, { useEffect, useState } from "react"

export const myContext = React.createContext()

const Provider = props => {
  const [bookmarks, setBookmarks] = useState(new Set())

  const addBookmarks = (accNo) => {
    setBookmarks(new Set(bookmarks.add(accNo)))
  }

  const removeBookmark = (accNo) => {
    const newBookmarks = new Set(bookmarks)
    newBookmarks.delete(accNo)
    setBookmarks(newBookmarks)
  }

  const clearall = (accNo) => {
    setBookmarks(new Set())
  }

  // On load, get bookmarks array from local storage, convert to Set and set state
  useEffect(() => {
    setBookmarks(new Set(JSON.parse(localStorage?.getItem("bookmark")) || []))
  }, [])

  // Store bookmarks as array in local storage
  useEffect(() => {
    localStorage.setItem("bookmark", JSON.stringify([...bookmarks]))
  }, [bookmarks])

  return (
    <myContext.Provider
      value={{
        bookmarks,
        addBookmarks,
        removeBookmark,
        clearall,
      }}
    >
      {props.children}
    </myContext.Provider>
  )
}

const MyProvider = ({ element }) => <Provider>{element}</Provider>

export default MyProvider