import React from "react"
import ThemeTopLayout from "gatsby-theme-material-ui-top-layout/src/components/top-layout"
import { CssBaseline } from "@material-ui/core"

const TopLayout = ({ children, theme }) => {
  return (
    <ThemeTopLayout theme={theme}>
      <CssBaseline>{children}</CssBaseline>
    </ThemeTopLayout>
  )
}

export default TopLayout