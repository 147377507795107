import { createTheme } from "@material-ui/core";

const defaultTheme = createTheme()
const theme = createTheme({
  palette: {
    primary: {
      main: "#D03200",
    },
    secondary: {
      main: "#fafafa",
    },
  },
  typography: {
    fontFamily: ["Libre Franklin", "sans-serif"].join(","),
    button: {
      fontFamily: "Zilla Slab",
      fontWeight: 700,
    },
  },
  shape: {
    borderRadius: 2,
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        p: {
          lineHeight: "1.75",
          fontSize: "1rem",
        },
        ol: {
          paddingLeft: 16,
        },
        li: {
          lineHeight: "1.75",
          fontSize: "1rem",
          paddingLeft: 16,
        },
        h2: {
          fontFamily: "Zilla Slab",
          marginBottom: 16,
          marginTop: "2rem",
          fontSize: "2.441rem",
          fontWeight: 700,
          lineHeight: 1.3,
        },
        h3: {
          fontFamily: "Zilla Slab",
          fontSize: "1.563rem",
          color: defaultTheme.palette.grey[800],
          marginTop: 16,
          marginBottom: 8,
        },
      },
    },
    MuiTypography: {
      h1: {
        fontFamily: "Zilla Slab",
        fontSize: "3.052rem",
        fontWeight: 700,
      },
      h2: {
        fontFamily: "Zilla Slab",
        fontSize: "2.441rem",
        fontWeight: 700,
        paddingBottom: "32px",
      },
      h3: {
        fontFamily: "Zilla Slab",
        color: defaultTheme.palette.grey[800],
        fontSize: "1.563rem",
        paddingBottom: "12px",
      },
      h4: {
        fontSize: "1.25rem",
        fontWeight: 700,
        paddingBottom: "12px",
      },
      h5: {
        fontFamily: "Zilla Slab",
        fontWeight: 700,
      },
      gutterBottom: {
        marginBottom: 32,
      },
    },
    MuiButton: {
      root: {
        fontFamily: "Libre Franklin",
        fontSize: "1rem",
        textTransform: "none",
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: defaultTheme.palette.grey[800],
      },
    },
  },
  props: {
    MuiGrid: {
      spacing: 3,
    },
    MuiButton: {
      disableElevation: true,
      color: "primary",
      variant: "contained",
    },
    MuiIconButton: {
      color: "primary",
    },
    MuiTextField: {
      variant: "outlined",
    },
  },
})

export default theme;